import { ref } from 'vue';

const showSnackbar = ref(null);

export function useSnackbar() {
  const register = (val) => {
    showSnackbar.value = val;
  };

  const triggerSnackbar = (message, type, timeout) => {
    if (showSnackbar.value) {
      showSnackbar.value(message, type, timeout);
    }
  };

  return {
    register,
    triggerSnackbar,
  };
}