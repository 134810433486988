<template>
	<div class="page login">
		<h1 class="campaign">Campaign Tester</h1>
		<div class="login-form">
			<h1>Login</h1>
			<p>Enter your WHIP credentials</p>
			<form @submit.prevent="handleLogin">
				<div class="field">
					<label for="username"></label>
					<input placeholder="Username" type="username" id="username" ref="usernameInput" v-model="username" required />
				</div>
				<div class="field">
					<label for="password"></label>
					<input placeholder="Password" type="password" id="password" v-model="password" required />
				</div>
				<ButtonComponent text="Login" type="submit" @click="handleLogin" />
			</form>
			<SnackbarComponent />
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";

// Services
import { loginUser, setAuthToken } from "@/services/api";
import { useSnackbar } from '@/services/useSnackbar';

// Components
import ButtonComponent from "@/components/ButtonComponent.vue";
import SnackbarComponent from "@/components/SnackbarComponent.vue";

// Authentication
import { login } from "@/js/auth";

const usernameInput = ref(null);
const username = ref("");
const password = ref("");
const router = useRouter();
const { triggerSnackbar } = useSnackbar();

onMounted(() => {
	if (usernameInput.value) usernameInput.value.focus();
});

const handleLogin = async () => {
	try {
		const loginData = {
			username: username.value,
			password: password.value,
		};
		const response = await loginUser(loginData);

		// Show error message using the Snackbar component
		triggerSnackbar('You have successfully logged in', 'error', 2000);

		// Save token to localStorage
		const accessToken = response.data.access_token;
		localStorage.setItem("access_token", accessToken);
		// Set access token in axios instance
		setAuthToken(accessToken);
		// Update global state
		login(accessToken);
		// Redirect to protected route Homepage Campaigns
		router.push({ name: "HomePage" });
	} catch (error) {
		console.log("Login failed: ", error);
		// Show error message using the Snackbar component
		triggerSnackbar('Login failed:' + error.response.data.msg, 'error', 2000);
	}
};
</script>

<style scoped lang="scss">
.login {
	text-align: center;
	display: flex;
	flex-direction: column;
	gap: 16px;
	align-items: center;
	justify-content: space-between;
	min-height: 92%;

	h1 {
		transition: all 0.2s ease;

		&.campaign {
			font-size: 48px;

			@media (max-width: 464px) {
				font-size: 38px;
			}

			@media (max-width: 372px) {
				font-size: 32px;
			}
		}
	}

	.login-form {
		width: 400px;
		margin: 0 auto;
		height: calc(100% - 48px);
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		gap: 8px;
		position: relative;
		padding-bottom: 72px;
		flex-grow: 1;

		@media (max-width: 464px) {
			width: 100%;
			padding-bottom: 57px;
		}

		@media (max-width: 372px) {
			padding-bottom: 48px;
		}

		p {
			margin-bottom: 32px;
			font-weight: lighter;
		}

		form {
			width: 100%;
			display: flex;
			align-items: center;
			flex-direction: column;
			gap: 24px;

			.field {
				width: 100%;

				input {
					height: 50px;
					width: 100%;
					border-radius: 28px;
					padding: 0 16px;
					background-color: #ffffff;

					&:focus-visible {
						outline: #1e90ff auto 1px !important;
					}
				}
			}

			button {
				background-color: #1e90ff;
				height: 50px;
				width: 100%;
				border-radius: 28px;
				padding: 0 16px;
				font-weight: bolder;
			}
		}

		.v-alert {
			width: 100%;
			min-width: 300px;
			height: auto;
			position: absolute;
			bottom: -40px;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}
</style>