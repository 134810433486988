<template>
	<button :type="props.type" :disabled="props.disabled" :class="props.class">
		<v-icon v-if="props.icon" :icon="props.icon"></v-icon>
		<span v-if="props.text">{{ props.text }}</span>
		</button>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
	text: {
		type: String,
		required: true,
		default: null,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
	class: {
		type: String,
		default: "primary",
	},
	type: {
		type: String,
		default: "button",
	},
	icon: {
		type: String,
		default: null,
	},
});
</script>

<style scoped lang="scss">
button {
	background-color: #1e90ff;
	height: 50px;
	width: 100%;
	color: #ffffff;
	border-radius: 28px;
	padding: 0 24px;
	font-weight: bolder;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;

	&.secondary {
		background-color: #151515;
		height: 45px;
	}

	&.warning {
		background-color: #990000;
	}

	&.back {
		color: #151515;
		background-color: #ffffff;
	}

	&.disabled {
		opacity: 0.6;
		cursor: not-allowed;
	}
}
</style>