<template>
  <div class="text-center">
    <v-snackbar v-model="snackbar.visible" :timeout="snackbar.timeout" :color="snackbar.type">{{ snackbar.text }}</v-snackbar>
  </div>
</template>

<script setup>
import { ref } from 'vue';

// Services
import { useSnackbar } from '@/services/useSnackbar';

const snackbar = ref({
  visible: false,
  text: '',
  color: '',
  timeout: 2000
});

const showSnackbar = (message, type, timeout = 2000) => {
  snackbar.value.text = message;
  snackbar.value.visible = true;
  snackbar.value.type = type;
  snackbar.value.timeout = timeout;
};

useSnackbar().register(showSnackbar);
</script>

<style scoped>
</style>
