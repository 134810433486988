<template>
	<div class="page edit">
		<BreadcrumbComponent />
		<div class="wrapper">
			<h1>{{ campaignId }}</h1>
			<ButtonComponent type="button" class="warning" icon="mdi-delete-outline" @click="handleDelete" />
		</div>
		<v-container fluid class="json-formatter-container">
			<v-progress-circular v-if="isLoading" color="primary" indeterminate></v-progress-circular>
			<vue-json-pretty
				v-else
				:data="campaign"
				:showLineNumber="true"
				:virtual="true"
				:showLine="false"
				:showIcon="true"
				theme="light"
				height="550"
			/>
		</v-container>
		<div class="buttons-container" v-if="!isLoading">
			<ButtonComponent text="Run Test" type="button" @click="navigateToOutputPage('test')"/>
			<ButtonComponent text="Check Users" type="button" @click="navigateToOutputPage('users')"/>
		</div>
		<SnackbarComponent />
		<ModalComponent />
	</div>
</template>

<script setup>
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

// Services
import { getCampaign, deleteCampaign, runCampaignTest, getCampaignUsers } from "@/services/api";
import { useSnackbar } from '@/services/useSnackbar';
import { useModal } from '@/services/useModal';

// Vuex store
import { useCampaignStore } from "@/stores/campaignStore";

// Components
import BreadcrumbComponent from "@/components/BreadcrumbComponent.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import ModalComponent from "@/components/ModalComponent.vue";
import SnackbarComponent from "@/components/SnackbarComponent.vue";

const { triggerModal } = useModal();
const { triggerSnackbar } = useSnackbar();
const route = useRoute();
const router = useRouter();
const store = useCampaignStore();
const campaignId = ref("");
const campaign = ref([]);
const dialog = ref(false);
let isLoading = ref(true);

// Retrieve the campaign ID from route parameters or query parameters
onMounted(() => {
	if (route.params) campaignId.value = route.params.id;
	console.log("Campaign ID:", campaignId.value);
	// Fetch campaign details using the ID if needed
	fetchCampaign(campaignId.value);
});

const fetchCampaign = async (id) => {
	try {
		const response = await getCampaign(id);
		console.log("Campaign fetched:", response.data);
		campaign.value = response.data;
	} catch (error) {
		console.error("Error fetching campaign:", error);
	} finally {
		isLoading.value = false;
	}
};

const navigateToOutputPage = async (action) => {
	try {
		let response;
		if (action === "test") {
			response = await runCampaignTest(campaignId.value, { action: action });
		}
		if (action === "users") {
			response = await getCampaignUsers(campaignId.value);
		}
		store.setResponseOutput(response.data);
		console.log("Campaign test data:", response.data);

		// Redirect to output page
		router.push({ name: "JsonOutputPage", params: { id: campaignId.value } });
	} catch (error) {
		console.error("Error running test:", error);
	}
};

const handleDelete = () => {
	triggerModal({
		title: 'Delete Campaign',
		text: 'Are you sure you want to delete this campaign?',
		icon: 'mdi-alert',
		onConfirm: () => {
			onDeleteConfirm();
		},
	});
};

const onDeleteConfirm = async () => {
  try {
    await deleteCampaign(campaignId.value);
    console.log("Campaign deleted successfully");
    dialog.value = false;

		// Show success message using the Snackbar component
		triggerSnackbar('Campaign deleted', 'success', 2000);

		// Redirect to output page
		setTimeout(() => {
			router.push({ name: "HomePage" });
		}, 2000);
  } catch (error) {
    console.error("Failed to delete the campaign", error);
		// Show error message using the Snackbar component
		triggerSnackbar("Failed to delete campaign: " + error.response.data.msg, 'error', 2000);
  }
};
</script>

<style scoped lang="scss">
.edit {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 16px;

	.wrapper {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		gap: 16px;

		h1 {
			text-transform: capitalize;
		}

		button {
			max-width: 200px;

			&.warning {
				width: 48px;
			}
		}
	}

	.json-formatter-container {
		background-color: white !important;
		color: black !important;
		border-radius: 12px;
		min-height: 550px;
		display: flex;
		align-items: center;
		justify-content: center;

		.vjs-tree.is-virtual {
			width: 100%;
		}
	}

	.buttons-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 8px;

		button {
			max-width: 250px;
		}
	}
}
</style>