<template>
	<div class="page home">
		<BreadcrumbComponent />
		<div class="buttons-container">
			<ButtonComponent text="New Campaign" type="button" icon="mdi-plus" @click="navigateToCreateCampaign" />
			<ButtonComponent class="export" text="" type="button" icon="mdi-download" @click="downloadCampaigns" />
		</div>

		<div class="campaigns-section">
			<h2>
				<span class="icon-container" @click="toggleActiveCampaigns">
					<v-icon v-if="activeCampaignsCollapsed" icon="mdi-chevron-down"></v-icon>
					<v-icon v-else icon="mdi-chevron-up"></v-icon>
				</span>
				Active Campaigns
			</h2>
			<div class="campaign-list" v-if="isLoading">
				<CardComponent
					v-for="index in 4"
					:key="index"
					:campaign="index"
					:isLoading="isLoading"
				/>
			</div>
			<div class="campaign-list" v-else :class="{ collapsed: activeCampaignsCollapsed }">
				<div class="campaign-top" v-if="activeCampaignsCollapsed"></div>
				<CardComponent
					v-for="campaign in activeCampaigns"
					:key="campaign.id"
					:campaign="campaign"
					@click="navigateToCampaign(campaign.id)"
				/>
			</div>
		</div>

		<div class="campaigns-section">
			<h2>
				<span class="icon-container" @click="toggleInactiveCampaigns">
					<v-icon v-if="activeCampaignsCollapsed" icon="mdi-chevron-down"></v-icon>
					<v-icon v-else icon="mdi-chevron-up"></v-icon>
				</span>
				Inactive Campaigns
			</h2>
			<div class="campaign-list" v-if="isLoading">
				<CardComponent
					v-for="index in 4"
					:key="index"
					:campaign="index"
					:isLoading="isLoading"
				/>
			</div>
			<div class="campaign-list" v-else :class="{ collapsed: inactiveCampaignsCollapsed }">
				<div class="campaign-top" v-if="inactiveCampaignsCollapsed"></div>
				<CardComponent
					v-for="campaign in inactiveCampaigns"
					:key="campaign.id"
					:campaign="campaign"
					@click="navigateToCampaign(campaign.id)"
				/>
			</div>
		</div>
		<SnackbarComponent />
	</div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { computed } from "vue";
import { useRouter } from "vue-router";

// Services
import { getCampaigns } from "@/services/api";
import { useSnackbar } from '@/services/useSnackbar';

// Components
import CardComponent from "@/components/CardComponent.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import BreadcrumbComponent from "@/components/BreadcrumbComponent.vue";
import SnackbarComponent from "@/components/SnackbarComponent.vue";

let campaigns = ref([]);
const isLoading = ref(true);
const router = useRouter();
const activeCampaigns = computed(() => campaigns.value.filter((campaign) => campaign.is_active));
const inactiveCampaigns = computed(() => campaigns.value.filter((campaign) => !campaign.is_active));
const activeCampaignsCollapsed = ref(false);
const inactiveCampaignsCollapsed = ref(false);
const { triggerSnackbar } = useSnackbar();

const fetchCampaigns = async () => {
	try {
		const response = await getCampaigns();
		campaigns.value = response.data;
		console.log("Campaigns res:", response.data);
	} catch (error) {
		console.error("Error fetching campaigns:", error);
	} finally {
		isLoading.value = false;
	}
};

const navigateToCreateCampaign = () => {
	router.push({ name: "JsonCreatePage" });
};

const navigateToCampaign = (id) => {
	// Redirect to JsonOutputPage with the campaign ID
	router.push({ name: "JsonEditorPage", params: { id: id } });
};

const downloadCampaigns = () => {
	const campaignsData = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(campaigns.value, null, 2));
	const downloadAnchorNode = document.createElement("a");
	downloadAnchorNode.setAttribute("href", campaignsData);
	downloadAnchorNode.setAttribute("download", "campaigns.json");
	document.body.appendChild(downloadAnchorNode);
	downloadAnchorNode.click();
	// Show success message using the Snackbar component
	triggerSnackbar('Campaigns exported', 'success', 2000);
	downloadAnchorNode.remove();
};

const toggleActiveCampaigns = () => {
  activeCampaignsCollapsed.value = !activeCampaignsCollapsed.value;
};

const toggleInactiveCampaigns = () => {
  inactiveCampaignsCollapsed.value = !inactiveCampaignsCollapsed.value;
};

onMounted(() => {
	fetchCampaigns();
});
</script>

<style scoped lang="scss">
.home {
	h3 {
		margin: 40px 0 0;
	}

	.buttons-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 8px;
		margin: 32px 0;

		button {
			max-width: 200px;

			&:nth-child(2) {
				width: 48px;
				background-color: #FFFFFF;
				color: #000000;
			}
		}
	}

	.campaigns-section {
		h2 {
			padding-bottom: 16px;

			i {
				cursor: pointer;
			}
		}

		& + .campaigns-section {
			padding-top: 32px;
		}
	}

	.campaign-list {
		display: flex;
		flex-wrap: wrap;
		gap: 16px;
		transition: all 0.2s ease;
		height: auto;
		position: relative;
		visibility: visible;
		opacity: 1;
		overflow: hidden;

		.campaign-top {
			position: absolute;
			top: 0;
			height: 100px;
			width: 100%;
			background: linear-gradient(0deg, #242424 0%, rgba(36, 36, 36, 0) 100%);
			z-index: 1;
		}

		&.collapsed {
			overflow: hidden;
			height: 100px;
			background: linear-gradient(0deg, #242424 0%, rgba(36, 36, 36, 0) 100%);
		}

		& > * {
			flex: 1 1 calc(50% - 16px);

			@media (max-width: 768px) {
				flex: 1 1 100%;
			}
		}
	}
}
</style>
