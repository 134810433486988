<template>
	<div class="page output">
		<h1>404 Page Not Found</h1>
	</div>
</template>

<script setup>
</script>

<style scoped lang="scss">
.page {
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>