import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL + process.env.VUE_APP_API_VERSION,
  headers: {
    "Content-Type": "application/json",
  },
});

// Set authorization header
export const setAuthToken = (token) => {
  if (token) {
    apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete apiClient.defaults.headers.common["Authorization"];
  }
};

// Set access_token on application initialization
const initializeAuthToken = () => {
  const access_token = localStorage.getItem("access_token");
  if (access_token) {
    setAuthToken(access_token);
  }
};

initializeAuthToken();

// Campaigns API
export const getCampaigns = () => {
  return apiClient.get("/campaigns/");
};

export const createCampaign = (campaignData) => {
  return apiClient.post("/campaigns/", campaignData);
};

export const getCampaign = (campaignId) => {
  return apiClient.get(`/campaigns/${campaignId}`);
};

export const deleteCampaign = (campaignId) => {
  return apiClient.delete(`/campaigns/${campaignId}`);
};

export const runCampaignTest = (campaignId, action) => {
  return apiClient.post(`/campaigns/${campaignId}`, action);
};

export const getCampaignUsers = (campaignId) => {
  return apiClient.get(`/campaigns/${campaignId}/users`);
};

// User API
export const loginUser = (loginData) => {
  return apiClient.post("/users/login", loginData);
};

export default {
  getCampaigns,
  createCampaign,
  getCampaign,
  deleteCampaign,
  runCampaignTest,
  getCampaignUsers,
  loginUser,
};