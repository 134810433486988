<template>
	<v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
</template>

<script setup>
import { useRoute } from "vue-router";
import { ref, onMounted, watch } from "vue";

const route = useRoute();
const routePath = route.path;
const breadcrumbs = ref([]);

// Parse the route path into breadcrumb items
const generateBreadcrumbs = (routePath) => {
	const segments = routePath.split("/").filter(Boolean);
	let breadcrumbs = [];

	// Add home/root breadcrumb
	if (segments.length === 0) {
		breadcrumbs.push({ title: "campaigns", disabled: true, href: "/" });
	}
	// Generate breadcrumbs for each segment
	segments.forEach((segment, index) => {
		const breadcrumb = {
			title: segment, // You can customize this text as needed
			disabled: index === segments.length - 1, // Disable last breadcrumb
			href: `/${segments.slice(0, index + 1).join("/")}`, // Generate href for each breadcrumb
		};
		breadcrumbs.push(breadcrumb);
	});
	return breadcrumbs;
};

// Watch route changes and update breadcrumbs
watch(route, () => {
	breadcrumbs.value = generateBreadcrumbs(routePath);
});

// Initial fetch of breadcrumbs on component mount
onMounted(() => {
	breadcrumbs.value = generateBreadcrumbs(routePath);
});
</script>

<style scoped lang="scss">
</style>