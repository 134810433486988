<template>
	<div class="card-container loading" v-if="props.isLoading"></div>
	<div class="card-container" v-else >
		<v-chip :class="['chip', getChipColor(props.campaign.type)]">{{ props.campaign.type }}</v-chip>
		<h3>{{ props.campaign.id }}</h3>
		<p>{{ props.campaign.gateway }}</p>
	</div>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
	campaign: {
		type: Object,
		required: true,
	},
	isLoading: {
		type: Boolean,
		default: false,
	}
});

const getChipColor = (campaignType) => {
	switch (campaignType) {
		case 'transactional':
			return 'bg-custom-blue';
		case 'newsletter':
			return 'bg-custom-orange';
		default:
			return '';
	}
}
</script>

<style scoped lang="scss">
.card-container {
	cursor: pointer;
	background-color: #ffffff;
	padding: 16px;
	color: #151515;
	max-width: 400px;
	height: 125px;
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	gap: 8px;
	transition: all 0.2s ease;

	&.loading {
		background: #e0e0e0;
    background: linear-gradient(100deg, #e0e0e0 8%, #d0d0d0 18%, #e0e0e0 33%);
    background-size: 200% 100%;
    animation: 0.8s shine linear infinite;
	}

	.chip {
		font-size: 12px;
		background-color: #000000;
		color: #ffffff;
		text-transform: uppercase;
		width: max-content;
		height: 22px;
	}

	.bg-custom-blue {
		background-color: #6cb6f2;
	}
	.bg-custom-orange {
		background-color: #ed706b;
	}

	h3,
	p {
		text-transform: capitalize;
	}
}

@keyframes shine {
	to {
		background-position-x: -200%;
	}
}
</style>