import { defineStore } from "pinia";

export const useCampaignStore = defineStore("campaign", {
  state: () => ({
    canAccessOutputPage: false,
    responseOutput: {},
  }),
  actions: {
    setResponseOutput(data) {
      this.responseOutput = data;
      this.canAccessOutputPage = true;
    },
    resetAccessOutputPage() {
      this.canAccessOutputPage = false;
    }
  },
});