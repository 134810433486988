import { ref } from "vue";

const isAuthenticated = ref(!!localStorage.getItem("access_token"));

const login = (token) => {
  localStorage.setItem("access_token", token);
  isAuthenticated.value = true;
};

const logout = () => {
  localStorage.removeItem("access_token");
  isAuthenticated.value = false;
};

export { isAuthenticated, login, logout };