<template>
  <v-dialog v-model="modal.visible" max-width="400" persistent>
    <v-card :prepend-icon="modal.icon" :title="modal.title" :text="modal.text">
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <v-btn @click="closeModal"> No </v-btn>
        <v-btn @click="confirmAction"> Yes </v-btn>
      </template>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref } from 'vue';
import { useModal } from '@/services/useModal';

const modal = ref({
  visible: false,
  title: '',
  text: '',
  icon: '',
  onConfirm: null,
});

const showModal = (options) => {
  modal.value.title = options.title;
  modal.value.text = options.text;
  modal.value.icon = options.icon || null;
  modal.value.onConfirm = options.onConfirm || null;
  modal.value.visible = true;
};

const closeModal = () => {
  modal.value.visible = false;
};

const confirmAction = () => {
  if (modal.value.onConfirm) {
    modal.value.onConfirm();
  }
  closeModal();
};

useModal().register(showModal);
</script>

<style scoped>
</style>
