<template>
	<div class="page create">
		<BreadcrumbComponent />
		<h1>New Campaign</h1>
		<div class="json-formatter-container">
			<json-editor	height="580" mode="text" v-model="jsonData" />
		</div>
		<ButtonComponent text="Create Campaign" type="button" @click="handleCreateCampaign" />
		<SnackbarComponent />
	</div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import JsonEditor from "vue3-ts-jsoneditor";

// Components
import ButtonComponent from "@/components/ButtonComponent.vue";
import BreadcrumbComponent from "@/components/BreadcrumbComponent.vue";
import SnackbarComponent from "@/components/SnackbarComponent.vue";

// Services
import { useSnackbar } from '@/services/useSnackbar';
import { createCampaign } from "@/services/api";

const { triggerSnackbar } = useSnackbar();
const schema = ref({
  "id": "string",
  "type": "transactional",
	"is_active": false,
  "filters": [
    {
      "field": "string",
      "values": [
        "string"
      ],
      "condition": "string"
    }
  ],
  "data": {},
  "gateway": "string",
  "scheduling": {
    "from": "2024-07-09T14:47:49.007Z",
    "to": "2024-07-09T14:47:49.007Z",
    "expression": "string"
  }
});
const jsonData = ref(schema.value);
const router = useRouter();

const handleCreateCampaign = async () => {
	try {
		const campaignData = jsonData.value;
		const response = await createCampaign(campaignData);
		console.log("Campaign created:", response.data);

		// Reset json editor after successful creation
		jsonData.value = schema.value;

		// Show success message using the Snackbar component
		triggerSnackbar('Campaign created', 'success', 2000);

		// Redirect to JsonOutputPage with the new campaign ID as a query parameter
		const newCampaignId = response.data.id;
		setTimeout(() => {
			router.push({ name: "JsonEditorPage", params: { id: newCampaignId } });
		}, 2000);
	} catch (error) {
		console.error("Failed to create campaign:", error);
		// Show error message using the Snackbar component
		triggerSnackbar("Failed to create campaign: " + error.response.data.msg, 'error', 2000);
	}
};
</script>

<style scoped lang="scss">
.create {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 16px;

	.json-formatter-container {
		overflow: hidden;
		background-color: white !important;
		color: black !important;
		border-radius: 12px;
	}

	button {
		max-width: 250px;
	}
}
</style>