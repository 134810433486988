import { ref } from 'vue';

const showModal = ref(null);

export function useModal() {
  const register = (val) => {
    showModal.value = val;
  };

  const triggerModal = (options) => {
    if (showModal.value) {
      showModal.value(options);
    }
  };

  return {
    register,
    triggerModal,
  };
}