<template>
	<nav>
		<div class="content">
			<img alt="WHIP Logo" src="@/assets/LOGO-dark.png" @click="goToHomePage" />
			<div class="user">
				<ButtonComponent :text="isAuthenticated ? 'Sign Out' : 'Sign In'" class="secondary" @click="handleAuth" />
				<AvatarComponent />
			</div>
		</div>
	</nav>
</template>

<script setup>
import { useRouter, useRoute } from "vue-router";

// Authentication
import { isAuthenticated, logout } from "@/js/auth";

// Components
import ButtonComponent from "@/components/ButtonComponent.vue";
import AvatarComponent from "@/components/AvatarComponent.vue";

const router = useRouter();
const route = useRoute();

const handleAuth = () => {
	if (isAuthenticated.value) {
		// Perform logout
		logout();
		isAuthenticated.value = false;
	}
	// If user is not logged in or after logout, then redirect to login page
	if (route.name !== "LoginPage") {
		router.push({ name: "LoginPage" });
	}
};

const goToHomePage = () => {
	if (route.name !== "HomePage" && isAuthenticated.value) {
		router.push({ name: "HomePage" });
	}
};
</script>

<style scoped lang="scss">
nav {
	padding: 0 16px;
	height: 80px;
	color: #151515;
	background-color: #ffffff;

	.content {
		//max-width: 880px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 auto;

		img {
			cursor: pointer;
			max-height: 80px;
			width: auto;
		}

		.user {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 8px;
		}
	}
}
</style>