// * Router
import { createWebHistory, createRouter } from "vue-router";
import { isAuthenticated } from "@/js/auth";
import { useCampaignStore } from "@/stores/campaignStore";

// Components
import LoginPage from "@/pages/LoginPage.vue";
import HomePage from "@/pages/HomePage.vue";
import JsonCreatePage from "@/pages/JsonCreatePage.vue";
import JsonEditorPage from "@/pages/JsonEditorPage.vue";
import JsonOutputPage from "@/pages/JsonOutputPage.vue";
import NotFoundPage from "@/pages/NotFoundPage.vue";

const routes = [
  { 
    path: "/", 
    component: HomePage, 
    meta: { requiresAuth: true } 
  },
  { 
    path: "/login", 
    name: "LoginPage", 
    component: LoginPage 
  },
  {
    path: "/campaigns",
    name: "HomePage",
    component: HomePage,
    meta: { requiresAuth: true },
  },
  {
    path: "/campaigns/create-campaign",
    name: "JsonCreatePage",
    component: JsonCreatePage,
    meta: { requiresAuth: true },
  },
  {
    path: "/campaigns/:id",
    name: "JsonEditorPage",
    component: JsonEditorPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/campaigns/:id/output",
    name: "JsonOutputPage",
    component: JsonOutputPage,
    meta: { requiresAuth: true },
  },
  { 
    path: "/:pathMatch(.*)*", 
    name: "NotFoundPage", 
    component: NotFoundPage 
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const store = useCampaignStore();

  // Check if the user is trying to access the login page
  if (to.name === "LoginPage" && isAuthenticated.value) {
    // If authenticated, redirect to the /campaigns page
    next({ name: "HomePage" });
  } else if (to.meta.requiresAuth && !isAuthenticated.value) {
    // If not authenticated, redirect to the login page
    next({ name: "LoginPage" });
  } else if (to.name === "JsonOutputPage" && !store.canAccessOutputPage) {
    console.log("dont have responseoutput", from);
    next({ name: "HomePage" });
  } else {
    // Proceed to the requested route
    next();
  }
});

export default router;