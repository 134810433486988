<template>
	<div class="page output">
		<BreadcrumbComponent />
		<h1>{{ campaignId }}</h1>
		<v-container fluid class="json-formatter-container">
			<v-progress-circular v-if="isLoading" color="primary" indeterminate></v-progress-circular>
			<vue-json-pretty
				v-else
				:data="responseOutput"
				:showLineNumber="true"
				:virtual="true"
				:showLine="false"
				:showIcon="true"
				theme="light"
				height="615"
			/>
		</v-container>
	</div>
</template>

<script setup>
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { computed } from "vue";

// Vuex store
import { useCampaignStore } from "@/stores/campaignStore";

// Components
import BreadcrumbComponent from "@/components/BreadcrumbComponent.vue";

const campaignId = ref("");
const route = useRoute();
const store = useCampaignStore();
const responseOutput = computed(() => store.responseOutput);
let isLoading = ref(true);

// Retrieve the campaign ID from route parameters and output from Pinia store
onMounted(() => {
	campaignId.value = route.params.id;
	if (store.responseOutput) isLoading.value = false;
	console.log("Campaign ID: ", campaignId.value + " - ", store.responseOutput);
});
</script>

<style scoped lang="scss">
.output {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 16px;

	.json-formatter-container {
		background-color: white !important;
		color: black !important;
		border-radius: 12px;
		min-height: 615px;
		display: flex;
		align-items: center;
		justify-content: center;

		.vjs-tree.is-virtual {
			width: 100%;
		}
	}
}
</style>